<template>
  <div class="pup-c-modal-editor">
    <div @click="toggleModal" class="pup-c-modal-editor--toggle">
      <pup-generic-text
        v-if="showPreview && !hidePreview"
        :placeholder="placeholder"
        :value="trim ? getString() : value"
        :autofocusable="false"
        :parseVariables="parseVariables"
        :disabled="disabled"
        :readonly="readonly"
      />
    </div>

    <pds-modal-component
      v-if="isModal"
      :isFixedHeight="true"
      :headline="disabled ? 'Read' : title"
      :isFooter="isFooter"
      :disabled="disabled"
      :saveCta="readonly ? 'Copy to clipboard' : 'Save'"
      cancelCta="Download"
      @download="download"
      @copy="readonly ? copy() : save()"
      @close="toggleModal"
    >
      <div :class="['pup-c-modal-editor--container']">
        <div
          class="pup-c-input-group--holder pds-u-m--b--8"
          :class="[
            statusClass ? `pup-c-input-group--holder--${statusClass}` : '',
          ]"
        >
          <label class="pup-c-input-group--label pds-u-subtitle--2">{{
            label
          }}</label>
          <pds-icon
            v-if="tooltip"
            class="material-icons--grey pds-u-m--l--8"
            icon="info"
            :isOutlined="true"
            v-tooltip="{
              content: tooltip,
            }"
          />
        </div>
        <div
          id="contentToCopy"
          class="test"
          style="width: 100%; height: 100%"
        />
        <pds-validation-message
          :status="status"
          @statusClassChanged="(e) => (statusClass = e)"
        />
        <pup-data-model-selector
          v-if="isDataModelSelectorVisible && !disabled"
          :parentId="parent.parentId"
          :dataModelList="[]"
          :class="[
            'pup-c-input-data-model-selector--acordeon',
            'pup-c-input-data-model-selector--acordeon--right',
          ]"
          style="top: 0"
          @pickElement="selectVariable"
          @closed="closeDataModelSelector"
          :processVariables="processVariables"
        />
      </div>
    </pds-modal-component>
  </div>
</template>

<script lang="ts">
import { mixins } from "vue-class-component";
import { Prop, Component, Model, Watch, Emit } from "vue-property-decorator";
import * as monaco from "monaco-editor";

import {
  IconComponent,
  ValidationMessage,
  ModalComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";

import DataModelSelector from "@/modules/ProcessDesigner/components/DataModelSelector/DataModelSelector.component.vue";

import { Node } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";

import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";
import { VariableParser } from "@/modules/ProcessDesigner/Variables/Utils/VariableParser";
import { Variable } from "@/modules/ProcessDesigner/Variables/Utils/Variable";
import { isValidJSON } from "@/utils/type/json";
import { TEMPLATE_VARIABLE_PLACEHOLDER } from "@/services/templates/Templates.model";
import { EditorLanguage } from "../Editor/Editor.model";

@Component({
  components: {
    "pup-data-model-selector": DataModelSelector,
    "pds-icon": IconComponent,
    "pds-validation-message": ValidationMessage,
    "pds-modal-component": ModalComponent,
    "pup-generic-text": GenericText,
  },
})
export default class ModalEditorComponent extends mixins(
  VariableParser,
  Variable
) {
  @Prop() tooltip?: string;

  @Prop() parent!: Node;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop({ default: "" }) placeholder!: string;

  @Prop() processVariables!: ProcessVariable[];

  @Model("update-input") value!: string;

  @Prop({ default: true }) canEdit!: boolean;

  @Prop({ default: false }) trim!: boolean;

  @Prop({ default: "Edit" }) title!: string;

  @Prop({ default: false }) isFooter!: string;

  @Prop({ default: false, type: Boolean }) autoformat!: boolean;

  @Prop({ default: false, type: Boolean }) autoOpen!: boolean;

  // if false, ignore variables guids and show value as it is
  @Prop({ default: true, type: Boolean }) parseVariables!: boolean;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  @Prop({ default: false, type: Boolean }) readonly!: boolean;

  @Prop({ default: false, type: Boolean }) hidePreview!: boolean;

  guidRegex =
    /([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\.[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})*)/gm;

  statusClass = "";

  isModal = false;

  showPreview = true;

  isReplaceableValue = false;

  @Watch("value", { immediate: true, deep: true })
  valueChangeHandler(value: string | boolean | null) {
    if (value !== null) {
      if (typeof value === "string") {
        let internalValue = value;
        if (this.parseVariables) {
          internalValue = internalValue
            .replaceAll(this.guidRegex, (match) => {
              return this.parseVariableIdToName(match, true);
            })
            .replaceAll(this.placeholderRegex, () => {
              return "<%%>";
            });
        }

        if (this.autoformat && isValidJSON(internalValue)) {
          internalValue = JSON.stringify(JSON.parse(internalValue), null, 4);
        }

        this.internalValue = internalValue;
      }
      // check for number | boolean
      else if (!isNaN(value as any)) {
        this.internalValue = value.toString();
      }
    } else {
      this.internalValue = "";
    }
  }

  internalValue!: string;
  // @Watch("internalValue", { immediate: true, deep: true })
  // internValueChangeHandler(value: string) {
  //   console.log("internal value set", value);
  // }

  @Prop() label!: string;

  @Prop({ default: EditorLanguage.TEXT }) lang!: EditorLanguage;

  isDataModelSelectorVisible = false;

  mEdit!: monaco.editor.IStandaloneCodeEditor;

  lineNumber = 0;

  column = 0;

  mounted() {
    if (this.autoOpen) {
      this.toggleModal();
    }
  }

  @Watch("canEdit")
  onCanEditUpdate(canEdit: boolean) {
    if (!this.mEdit) {
      return;
    }
    this.mEdit.updateOptions({ readOnly: !canEdit });
  }

  @Watch("disabled")
  @Watch("readonly")
  onDisabledUpdate(isDisabled: boolean) {
    if (!this.mEdit) {
      return;
    }

    this.mEdit.updateOptions({
      readOnly: isDisabled || this.readonly,
      theme: "PROCESIO" + (isDisabled ? "DISABLED" : ""),
    });
  }

  mountEditor() {
    // Register a completion item provider for the new language

    const legend: monaco.languages.SemanticTokensLegend = {
      tokenTypes: ["<%%>"],
      tokenModifiers: [],
    };

    const tokenPattern = new RegExp("([a-zA-Z<%%>]+)((?:\\.[a-zA-Z]+)*)", "g");

    function getType(type: string) {
      return legend.tokenTypes.indexOf(type);
    }

    function getModifier(modifiers: string[]) {
      if (typeof modifiers === "string") {
        modifiers = [modifiers];
      }

      if (Array.isArray(modifiers)) {
        let nModifiers = 0;
        for (const modifier of modifiers) {
          const nModifier = legend.tokenModifiers.indexOf(modifier);
          if (nModifier > -1) {
            nModifiers |= (1 << nModifier) >>> 0;
          }
        }
        return nModifiers;
      } else {
        return 0;
      }
    }

    monaco.languages.registerDocumentSemanticTokensProvider(this.lang, {
      getLegend: function () {
        return legend;
      },
      releaseDocumentSemanticTokens: function (resultId) {
        console.log(resultId);
      },
      provideDocumentSemanticTokens: function (model) {
        const lines = model.getLinesContent();

        /** @type {number[]} */
        const data = [];

        let prevLine = 0;
        let prevChar = 0;

        for (let i = 0; i < lines.length; i++) {
          const line = lines[i];

          for (let match = null; (match = tokenPattern.exec(line)); ) {
            // translate token and modifiers to number representations
            const type = getType(match[1]);
            if (type === -1) {
              continue;
            }
            const modifier = match[2].length
              ? getModifier(match[2].split(".").slice(1))
              : 0;

            data.push(
              // translate line to deltaLine
              i - prevLine,
              // for the same line, translate start to deltaStart
              prevLine === i ? match.index - prevChar : match.index,
              match[0].length,
              type,
              modifier
            );

            prevLine = i;
            prevChar = match.index;
          }
        }
        return {
          data: new Uint32Array(data),
          resultId: undefined,
        };
      },
    });

    monaco.editor.defineTheme("PROCESIO", {
      base: "vs",
      inherit: false,
      rules: [{ token: "<%%>", foreground: "D11C2C", fontStyle: "bold" }],
      colors: {},
    });

    monaco.editor.defineTheme("PROCESIODISABLED", {
      base: "vs",
      inherit: true,
      rules: [
        {
          token: "<%%>",
          fontStyle: "bold",
          foreground: "f7f9fc",
          background: "f7f9fc",
        },
      ],
      colors: {
        "editor.background": "#f7f9fc",
        "editorRuler.foreground": "#f7f9fc",
        "minimap.background": "#f7f9fc",
        "editorLineNumber.foreground": "#8D9BB5",
      },
    });

    this.mEdit = monaco.editor.create(
      document.querySelector(".test") as HTMLElement,
      {
        value: this.internalValue,
        language: this.lang,
        theme: "PROCESIO",
        "semanticHighlighting.enabled": true,
      }
    );

    this.mEdit.addCommand(monaco.KeyCode.Insert, () => this.openSelector());
    this.mEdit.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_I, () =>
      this.openSelector()
    );

    this.mEdit.onDidChangeModelContent(() => {
      const position = this.mEdit.getPosition();
      this.lineNumber = position?.lineNumber as number;
      this.column = position?.column as number;

      this.emitChange(this.mEdit.getValue());
    });

    this.mEdit.onMouseUp((e) => {
      this.lineNumber = e.target.position?.lineNumber as number;
      this.column = e.target.position?.column as number;

      const valueByLine = this.mEdit
        .getModel()
        ?.getLineContent(this.lineNumber);

      if (
        valueByLine?.charAt(this.column - 3) === "<" &&
        valueByLine?.charAt(this.column - 2) === "%" &&
        valueByLine?.charAt(this.column - 1) === "%" &&
        valueByLine?.charAt(this.column) === ">"
        //   ||
        // (valueByLine?.charAt(this.column - 2) === "<" &&
        //   valueByLine?.charAt(this.column - 1) === "%" &&
        //   valueByLine?.charAt(this.column) === "%" &&
        //   valueByLine?.charAt(this.column + 1) === ">") ||
        // (valueByLine?.charAt(this.column - 4) === "<" &&
        //   valueByLine?.charAt(this.column - 3) === "%" &&
        //   valueByLine?.charAt(this.column - 2) === "%" &&
        //   valueByLine?.charAt(this.column - 1) === ">")
      ) {
        setTimeout(() => {
          this.isReplaceableValue = true;
          this.isDataModelSelectorVisible = true;
        }, 300);
      }
    });

    this.mEdit.onDidBlurEditorWidget((e: FocusEvent | null = null) =>
      this.$emit("blur", e)
    );

    this.onCanEditUpdate(this.canEdit);
    this.onDisabledUpdate(this.disabled);
  }

  @Emit("download")
  download() {
    return true;
  }

  @Emit("copy")
  copy() {
    return true;
  }

  getString() {
    let value = this.value;

    // check for number | boolean
    if (!isNaN(value as any)) {
      value = (value as any).toString();
    }
    return value;
  }

  emitChange(value: string) {
    const parsedValue = this.parseVariableNameToId(value).replaceAll(
      "<%%>",
      () => TEMPLATE_VARIABLE_PLACEHOLDER
    );
    // console.log("EMMITING NEW EDITOR VALUE:", parsedValue);

    this.$emit("update-input", parsedValue);
  }

  save() {
    const parsedValue = this.parseVariableNameToId(
      this.mEdit.getValue()
    ).replaceAll("<%%>", () => TEMPLATE_VARIABLE_PLACEHOLDER);

    this.$emit("save", parsedValue);
    this.toggleModal()
  }

  closeDataModelSelector(event: Event | null = null) {
    this.isDataModelSelectorVisible = false;
    if ((event as KeyboardEvent)?.code === "Escape" && this.mEdit) {
      this.mEdit.focus();
    }
  }

  selectVariable(variable: string) {
    // inset variable at caret position
    this.mEdit.executeEdits("", [
      {
        range: new monaco.Range(
          this.lineNumber,
          this.column,
          this.lineNumber,
          this.column
        ),
        text: this.parseVariableIdToName(variable, !this.isReplaceableValue),
      },
    ]);

    this.isDataModelSelectorVisible = false;
    this.isReplaceableValue = false;

    // refocus the edior
    this.mEdit.focus();

    // set the caret position after the new insertion
    this.mEdit.setPosition({
      lineNumber: this.lineNumber,
      column: this.column + variable.length + 1,
    });

    // scroll left to the new caret position
    this.mEdit.setScrollPosition({
      scrollLeft: this.column + variable.length + 1,
    });
  }

  toggleModal() {
    this.showPreview = false;

    this.isModal = !this.isModal;

    this.$nextTick(() => {
      if (this.isModal) {
        this.mountEditor();
      }

      this.showPreview = true;
    });

    this.$emit("toggleModal", this.isModal);
  }

  openSelector() {
    const position = this.mEdit.getPosition();
    this.lineNumber = position?.lineNumber as number;
    this.column = position?.column as number;
    this.isDataModelSelectorVisible = true;
  }
}
</script>

<style scoped lang="scss">
@import "./ModalEditor.component.scss";
</style>
