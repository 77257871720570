import {
  ActionResponseFull,
  ActionResponseLazy,
  Groups,
} from "@/services/actionlist/ActionList.service";
import {
  InstanceRunMode,
  Variable,
} from "@/services/crud/Orchestration.service";
import Vue from "vue";
import { MutationTree } from "vuex";

import { ProcessState } from "./Process.store";

import { Operator } from "@/services/actionlist/ActionList.service";
import { WarningI } from "@/modules/ProcessDesigner/Validation/Validation";
import { TestActionModel } from "@/modules/ProcessDesigner/components/Controls/TestAction/TestAction.model";

export enum MutationTypes {
  LOAD_VARIABLES = "LOAD_VARIABLES",
  SET_VARIABLE = "SET_VARIABLE",
  SET_VARIABLE_DELETED_STATE = "SET_VARIABLE_DELETED_STATE",
  SET_ACTION_LIST = "SET_ACTION_LIST",
  SET_GROUP_LIST = "SET_GROUP_LIST",
  SET_ACTION_CONFIGURATION = "SET_ACTION_CONFIGURATION",
  SET_SIDEPANEL_ID = "SET_SIDEPANEL_ID",
  SET_EXTRA_VARIABLES_BY_KEY = "SET_EXTRA_VARIABLES_BY_KEY",
  RESET_EXTRA_VARIABLES = "RESET_EXTRA_VARIABLES",
  LOAD_OPERAND_LIST = "LOAD_OPERAND_LIST",
  SET_ID = "SET_ID",
  SET_TITLE = "SET_TITLE",
  SET_DESCRIPTION = "SET_DESCRIPTION",
  SET_ACTIVE_STATE = "SET_ACTIVE_STATE",
  SET_LOADED_STATE = "SET_LOADED_STATE",
  SET_VALIDATED_STATE = "SET_VALIDATED_STATE",
  SET_VALIDATING_STATE = "SET_VALIDATING_STATE",
  SET_SAVED_STATE = "SET_SAVED_STATE",
  SET_SAVING_STATE = "SET_SAVING_STATE",
  SET_VALIDATION_ERRORS = "SET_VALIDATION_ERRORS",
  DELETE_VALIDATION_ERRORS = "DELETE_VALIDATION_ERRORS",
  SET_LAST_RUN_PROCESS_ID = "SET_LAST_RUN_PROCESS_ID",
  SET_LAST_RUN_INSTANCE_ID = "SET_LAST_RUN_INSTANCE_ID",
  SET_INSTANCE_ID = "SET_INSTANCE_ID",
  SET_INSTANCE_VARIABLES = "SET_INSTANCE_VARIABLES",
  SET_INSTANCE_UPDATE_DATE = "SET_INSTANCE_UPDATE_DATE",
  SET_INSTANCE_RUN_MODE = "SET_INSTANCE_RUN_MODE",
  SET_TEST_ACTION_MODEL = "SET_TEST_ACTION_MODEL",
}

export type Mutations<S = ProcessState> = {
  [MutationTypes.LOAD_VARIABLES](state: S, payload: Variable[]): void;

  [MutationTypes.SET_VARIABLE](state: S, payload: Variable): void;

  [MutationTypes.SET_VARIABLE_DELETED_STATE](
    state: S,
    payload: { variable: Variable; isDeleted: boolean }
  ): void;

  [MutationTypes.SET_ACTION_LIST](
    state: S,
    payload: ActionResponseLazy[]
  ): void;

  [MutationTypes.LOAD_OPERAND_LIST](state: S, payload: Operator[]): void;

  [MutationTypes.SET_GROUP_LIST](state: S, payload: Groups[]): void;

  [MutationTypes.SET_ACTION_CONFIGURATION](
    state: S,
    payload: ActionResponseFull
  ): void;

  [MutationTypes.SET_SIDEPANEL_ID](state: S, payload: string): void;

  [MutationTypes.SET_EXTRA_VARIABLES_BY_KEY](
    state: S,
    { nodeId, variables }: { nodeId: string; variables: Variable[] }
  ): void;

  [MutationTypes.RESET_EXTRA_VARIABLES](state: S): void;

  [MutationTypes.SET_ID](state: S, payload: string | null): void;

  [MutationTypes.SET_TITLE](state: S, payload: string | null): void;

  [MutationTypes.SET_DESCRIPTION](state: S, payload: string | null): void;

  [MutationTypes.SET_ACTIVE_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_LOADED_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_VALIDATED_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_VALIDATING_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_SAVED_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_SAVING_STATE](state: S, payload: boolean): void;

  [MutationTypes.SET_VALIDATION_ERRORS](state: S, payload: WarningI[]): void;

  [MutationTypes.DELETE_VALIDATION_ERRORS](
    state: S,
    payload: string | null
  ): void;

  [MutationTypes.SET_LAST_RUN_PROCESS_ID](
    state: S,
    payload: string | null
  ): void;

  [MutationTypes.SET_LAST_RUN_INSTANCE_ID](
    state: S,
    payload: string | null
  ): void;

  [MutationTypes.SET_INSTANCE_ID](state: S, payload: string | null): void;

  [MutationTypes.SET_INSTANCE_VARIABLES](state: S, payload: Variable[]): void;

  [MutationTypes.SET_INSTANCE_UPDATE_DATE](
    state: S,
    payload: string | null
  ): void;

  [MutationTypes.SET_INSTANCE_RUN_MODE](
    state: S,
    payload: InstanceRunMode
  ): void;

  [MutationTypes.SET_TEST_ACTION_MODEL](
    state: S,
    payload: TestActionModel | null
  ): void;
};

export const mutations: MutationTree<ProcessState> & Mutations = {
  [MutationTypes.LOAD_VARIABLES](state, payload) {
    state.variables = payload;
  },

  [MutationTypes.SET_VARIABLE](state, payload) {
    const existingVariableIndex = state.variables.findIndex(
      (val) => val.id === payload.id
    );

    if (existingVariableIndex === -1) {
      state.variables.push(payload);
    } else {
      Vue.set(state.variables, existingVariableIndex, {
        ...state.variables[existingVariableIndex],
        ...payload,
      });
    }
  },

  [MutationTypes.SET_VARIABLE_DELETED_STATE](state, { variable, isDeleted }) {
    const variableIndex = state.variables.findIndex(
      (val) => val.id === variable.id
    );

    if (variableIndex === -1) {
      return;
    }

    state.variables = state.variables.map((v, index) => {
      const variable = { ...v };
      if (index === variableIndex) {
        variable.isDeleted = isDeleted;
      }
      return variable;
    });
  },

  [MutationTypes.SET_ACTION_LIST](state, payload) {
    state.actionList = [...payload];
  },

  [MutationTypes.LOAD_OPERAND_LIST](state, payload) {
    state.operands = [...payload];
  },

  [MutationTypes.SET_GROUP_LIST](state, payload) {
    state.groups = [...payload];
  },

  [MutationTypes.SET_ACTION_CONFIGURATION](state, payload) {
    const isAction = state.actionList.find(
      (template) => template.internalId === payload.internalId
    );

    if (isAction) {
      state.actionList = state.actionList.map((template) => {
        if (template.internalId === payload.internalId) {
          return {
            ...template,
            ...payload,
          };
        } else {
          return template;
        }
      });
    } else {
      state.actionList.push(payload);
    }
  },

  [MutationTypes.SET_SIDEPANEL_ID](state, payload) {
    state.sidepanel = payload;
  },

  [MutationTypes.SET_EXTRA_VARIABLES_BY_KEY](
    state,
    { nodeId, variables }: { nodeId: string; variables: Variable[] }
  ) {
    Vue.set(state.extraVariablesGroupedByNodeId, nodeId, variables);
  },

  [MutationTypes.RESET_EXTRA_VARIABLES](state) {
    state.extraVariablesGroupedByNodeId = {};
  },

  [MutationTypes.SET_ID](state, payload) {
    state.id = payload;
  },

  [MutationTypes.SET_TITLE](state, payload) {
    state.title = payload;
  },

  [MutationTypes.SET_DESCRIPTION](state, payload) {
    state.description = payload;
  },

  [MutationTypes.SET_ACTIVE_STATE](state, payload) {
    state.isActive = payload;
  },

  [MutationTypes.SET_LOADED_STATE](state, payload) {
    state.isLoaded = payload;
  },

  [MutationTypes.SET_VALIDATED_STATE](state, payload) {
    state.isValidated = payload;
  },

  [MutationTypes.SET_VALIDATING_STATE](state, payload) {
    state.isValidating = payload;
  },

  [MutationTypes.SET_SAVED_STATE](state, payload) {
    state.isSaved = payload;
  },

  [MutationTypes.SET_SAVING_STATE](state, payload) {
    state.isSaving = payload;
  },

  [MutationTypes.SET_VALIDATION_ERRORS](state, payload) {
    state.validationErrors = payload;
  },

  [MutationTypes.DELETE_VALIDATION_ERRORS](
    state,
    payload: string | null = null
  ) {
    if (!payload) {
      state.validationErrors = [];
      return;
    }

    const errors = [...state.validationErrors];
    const errorIndex = errors.findIndex((e) => e.key === payload);
    if (errorIndex !== -1) {
      errors.splice(errorIndex, 1);
    }
    state.validationErrors = errors;
  },

  [MutationTypes.SET_LAST_RUN_PROCESS_ID](state, payload) {
    state.lastRunProcessId = payload;
  },

  [MutationTypes.SET_LAST_RUN_INSTANCE_ID](state, payload) {
    state.lastRunInstanceId = payload;
  },

  [MutationTypes.SET_INSTANCE_ID](state, payload) {
    state.instanceId = payload;
  },

  [MutationTypes.SET_INSTANCE_VARIABLES](state, payload) {
    state.instanceVariables = payload;
  },

  [MutationTypes.SET_INSTANCE_UPDATE_DATE](state, payload) {
    state.instanceUpdateDate = payload;
  },

  [MutationTypes.SET_INSTANCE_RUN_MODE](state, payload) {
    state.instanceRunMode = payload;
  },

  [MutationTypes.SET_TEST_ACTION_MODEL](state, payload) {
    state.testActionModel = payload;
  },
};
