import {
  ActionResponseLazy,
  Groups,
} from "@/services/actionlist/ActionList.service";
import {
  InstanceRunMode,
  Variable,
} from "@/services/crud/Orchestration.service";

import { actions } from "./Process.actions";
import { mutations } from "./Process.mutations";
import getters from "./Process.getters";

import { Operator } from "@/services/actionlist/ActionList.service";
import { WarningI } from "@/modules/ProcessDesigner/Validation/Validation";
import { TestActionModel } from "@/modules/ProcessDesigner/components/Controls/TestAction/TestAction.model";

export const ProcessStore = {
  state: () => ({
    variables: [],
    actionList: [],
    sidepanel: "",
    groups: [],
    extraVariablesGroupedByNodeId: {},
    operands: [],
    id: null,
    title: "",
    description: "",
    isActive: true,
    isLoaded: false,
    isValidated: false,
    isValidating: false,
    isSaved: false,
    isSaving: false,
    lastRunProcessId: null,
    lastRunInstanceId: null,
    instanceId: null,
    instanceVariables: [],
    instanceRunMode: InstanceRunMode.DEFAULT,
    instanceUpdateDate: null,
    validationErrors: [],
    testActionModel: null,
  }),
  mutations,
  actions,
  getters,
};

export interface ProcessState {
  variables: Variable[];
  actionList: ActionResponseLazy[];
  sidepanel: string;
  groups: Groups[];
  extraVariablesGroupedByNodeId: { [key: string]: Variable[] };
  operands: Operator[];
  id: string | null;
  title: string | null;
  description: string | null;
  isActive: boolean;
  isLoaded: boolean; // loading state: determinies if process data is loaded completely
  isValidated: boolean;
  isValidating: boolean;
  isSaved: boolean;
  isSaving: boolean;
  lastRunProcessId: string | null; // last run process id
  lastRunInstanceId: string | null; // last run instance id
  instanceId: string | null;
  instanceVariables: Variable[];
  instanceUpdateDate: string | null;
  instanceRunMode: InstanceRunMode;
  validationErrors: WarningI[];
  testActionModel: TestActionModel | null;
}
