import ls from "@/services/localStorage";
import UserLocalStorage from "@/services/localStorage/user";
import { Event } from "@/services/user/event/Event.model";
import EventService from "@/services/user/event/Event.service";
import UserService from "@/services/user/User.service";
import { Commit, Dispatch } from "vuex";
import UserWorkspaceService, {
  UserWorkspace,
} from "../../services/user/workspace/UserWorkspace.service";
import { MutationTypes } from "./User.mutations";
import { UserState } from "./User.store";
import { ActionTypes as ResourceActionTypes } from "@/store/resource/Resource.actions";
import { ActionTypes as UserPermissionsTypes } from "@/store/userPermissions/UserPermissions.actions";

export enum ActionTypes {
  LOAD_USER_ID = "LOAD_USER_ID",
  SET_USER_EMAIL = "SET_USER_EMAIL",
  SET_FIRST_NAME = "SET_FIRST_NAME",
  SET_LAST_NAME = "SET_LAST_NAME",
  SET_USER_CREATED_AT = "SET_USER_CREATED_AT",
  SET_TOKEN = "SET_TOKEN",
  SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
  SET_TOKEN_EXPIRES_IN = "SET_TOKEN_EXPIRES_IN",
  SET_REFRESH_TOKEN_EXPIRES_IN = "SET_REFRESH_TOKEN_EXPIRES_IN",
  SET_WORKSPACES = "SET_WORKSPACES",
  SET_ACTIVE_WORKSPACE = "SET_ACTIVE_WORKSPACE",
  SET_ACTIVE_WORKSPACE_PERSIST = "SET_ACTIVE_WORKSPACE_PERSIST", // keep in local storage
  LOAD_WORKSPACES = "LOAD_WORKSPACES",
  LOAD_EVENTS = "LOAD_EVENTS",
  SET_EVENTS = "SET_EVENTS",
  READ_EVENT = "READ_EVENT",
}

export const LsWorkspaceKey = "workspace";

export interface Actions {
  [ActionTypes.LOAD_USER_ID](
    { commit }: { commit: Commit }
  ): void;

  [ActionTypes.SET_USER_EMAIL](
    { commit }: { commit: Commit },
    payload: string
  ): void;

  [ActionTypes.SET_FIRST_NAME](
    { commit }: { commit: Commit },
    payload: string
  ): void;

  [ActionTypes.SET_LAST_NAME](
    { commit }: { commit: Commit },
    payload: string
  ): void;

  [ActionTypes.SET_USER_CREATED_AT](
    { commit }: { commit: Commit },
    payload: string | null
  ): void;

  [ActionTypes.SET_TOKEN](
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: string | null
  ): void;

  [ActionTypes.SET_REFRESH_TOKEN](
    { commit }: { commit: Commit },
    payload: string | null
  ): void;

  [ActionTypes.SET_TOKEN_EXPIRES_IN](
    { commit }: { commit: Commit },
    payload: number | null
  ): void;

  [ActionTypes.SET_REFRESH_TOKEN_EXPIRES_IN](
    { commit }: { commit: Commit },
    payload: number | null
  ): void;

  [ActionTypes.SET_WORKSPACES](
    { commit }: { commit: Commit },
    payload: UserWorkspace[]
  ): void;

  [ActionTypes.SET_ACTIVE_WORKSPACE](
    {
      commit,
      getters,
      dispatch,
    }: { commit: Commit; getters: any; dispatch: Dispatch },
    payload: UserWorkspace
  ): void;

  [ActionTypes.SET_ACTIVE_WORKSPACE_PERSIST](
    { dispatch }: { dispatch: Dispatch },
    payload: UserWorkspace
  ): void;

  [ActionTypes.LOAD_WORKSPACES]({
    dispatch,
  }: {
    dispatch: Dispatch;
  }): Promise<any>;

  [ActionTypes.LOAD_EVENTS]({
    dispatch,
    state,
  }: {
    dispatch: Dispatch;
    state: UserState;
  }): void;

  [ActionTypes.SET_EVENTS](
    { commit }: { commit: Commit },
    payload: Event[]
  ): void;

  [ActionTypes.READ_EVENT](
    { commit }: { commit: Commit },
    payload: string | null
  ): void;
}

export const actions: any = {
  async [ActionTypes.LOAD_USER_ID](
    { commit }: { commit: Commit },
  ) {
    const response = await UserService.getUserId();
    commit(MutationTypes.SET_USER_ID, response.content?.userId);
  },

  [ActionTypes.SET_USER_EMAIL]({ commit }: { commit: Commit }, email: string) {
    commit(MutationTypes.SET_USER_EMAIL, email);
  },

  [ActionTypes.SET_FIRST_NAME]({ commit }: { commit: Commit }, value: string) {
    commit(MutationTypes.SET_FIRST_NAME, value);
  },

  [ActionTypes.SET_LAST_NAME]({ commit }: { commit: Commit }, value: string) {
    commit(MutationTypes.SET_LAST_NAME, value);
  },

  [ActionTypes.SET_USER_CREATED_AT](
    { commit }: { commit: Commit },
    createdAt: string | null
  ) {
    commit(MutationTypes.SET_USER_CREATED_AT, createdAt);
  },

  [ActionTypes.SET_TOKEN](
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    token: string | null
  ) {
    commit(MutationTypes.SET_TOKEN, token);

    let userEmail = "";
    let createdAt = null;
    let firstName = "";
    let lastName = "";
    if (token) {
      UserLocalStorage.setToken(token);

      const userData = UserService.getUserData(token);

      userEmail = userData.email;
      createdAt = userData.created;
      firstName = userData.firstName;
      lastName = userData.lastName;
    } else {
      UserLocalStorage.removeToken();
    }

    dispatch(ActionTypes.SET_USER_EMAIL, userEmail);
    dispatch(ActionTypes.SET_USER_CREATED_AT, createdAt);
    dispatch(ActionTypes.SET_FIRST_NAME, firstName);
    dispatch(ActionTypes.SET_LAST_NAME, lastName);
  },

  [ActionTypes.SET_REFRESH_TOKEN](
    { commit }: { commit: Commit },
    token: string | null
  ) {
    commit(MutationTypes.SET_REFRESH_TOKEN, token);
    if (token) {
      UserLocalStorage.setRefreshToken(token);
    } else {
      UserLocalStorage.removeRefreshToken();
    }
  },

  [ActionTypes.SET_TOKEN_EXPIRES_IN](
    { commit }: { commit: Commit },
    expiresIn: number | null
  ) {
    commit(MutationTypes.SET_TOKEN_EXPIRES_IN, expiresIn);
    if (expiresIn !== null) {
      UserLocalStorage.setTokenExpiresIn(expiresIn);
    } else {
      UserLocalStorage.removeTokenExpiresIn();
    }
  },

  [ActionTypes.SET_REFRESH_TOKEN_EXPIRES_IN](
    { commit }: { commit: Commit },
    expiresIn: number | null
  ) {
    commit(MutationTypes.SET_REFRESH_TOKEN_EXPIRES_IN, expiresIn);
    if (expiresIn !== null) {
      UserLocalStorage.setRefreshTokenExpiresIn(expiresIn);
    } else {
      UserLocalStorage.removeRefreshTokenExpiresIn();
    }
  },

  [ActionTypes.SET_WORKSPACES](
    { commit }: { commit: Commit },
    workspaces: UserWorkspace[]
  ) {
    commit(MutationTypes.SET_WORKSPACES, workspaces);
  },

  async [ActionTypes.SET_ACTIVE_WORKSPACE](
    {
      commit,
      getters,
      dispatch,
    }: { commit: Commit; getters: any; dispatch: Dispatch },
    workspace: UserWorkspace | null
  ) {
    if (!workspace?.hasAccess) {
      workspace = getters.personalWorkspace;
    }

    if (workspace) {
      await dispatch(UserPermissionsTypes.LOAD_USER_PERMISSIONS, workspace.id);
      commit(MutationTypes.SET_ACTIVE_WORKSPACE, workspace);
    }

    dispatch(ResourceActionTypes.LOAD_TIME);

    return workspace;
  },

  async [ActionTypes.SET_ACTIVE_WORKSPACE_PERSIST](
    { dispatch }: { dispatch: Dispatch },
    workspace: UserWorkspace | null
  ) {
    workspace = await dispatch(ActionTypes.SET_ACTIVE_WORKSPACE, workspace);

    if (workspace) {
      ls.set(LsWorkspaceKey, workspace.id || "");
    }
  },

  async [ActionTypes.LOAD_WORKSPACES]({ dispatch }: { dispatch: Dispatch }) {
    const data = await UserWorkspaceService.getWorkspaceData();
    await dispatch(ActionTypes.SET_WORKSPACES, data.workspaces || []);
    await dispatch(
      ActionTypes.SET_ACTIVE_WORKSPACE_PERSIST,
      data.activeWorkspace || null
    );
  },

  async [ActionTypes.LOAD_EVENTS](
    { dispatch, state }: { dispatch: Dispatch; state: UserState },
    isSilent = false
  ) {
    const data = await EventService.getEventList();

    let events: Event[] = [];
    if (data.content && !data.is_error) {
      events = (Array.isArray(data.content) ? data.content : []).map(
        (event) => {
          // silent mode for existing events to do not show popups again
          if (
            state.events.findIndex((e) => e.eventId === event.eventId) !== -1
          ) {
            isSilent = true;
          }
          event.isSilent = isSilent;
          return event;
        }
      );
    }

    await dispatch(ActionTypes.SET_EVENTS, events);
  },

  [ActionTypes.SET_EVENTS]({ commit }: { commit: Commit }, events: Event[]) {
    commit(MutationTypes.SET_EVENTS, events);
  },

  [ActionTypes.READ_EVENT]({ commit }: { commit: Commit }, id: string | null) {
    EventService.updateEvent(id);
    commit(MutationTypes.READ_EVENT, id);
  },
};
