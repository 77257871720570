import { config } from "@/config";
import { HubConnectionBuilder } from "@microsoft/signalr";

export enum SignalRMethodNames {
  TEST_ACTION = "test-action",
  GENERATE_DATA_MODEL = "generate-data",
  USER_DATA_UPDATED = "user-data-updated",
  RESOURCES_UPDATED = "resources-updated",
  PROCESS_INSTANCE_DEBUGGER_UPDATED = "debugger-action-done",
}

export class SignalRImplementation {
  static certificate = config.protocol;
  static baseUrl = config.notifications;

  static initSignalRConnection() {
    const url = `${this.certificate}://${this.baseUrl}/hub/notification`;
    const connection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(() => console.log("SignalR connection on"))
      .catch((err) => console.error(err));

    return connection;
  }
}
