export const START_ACTION_TEMPLATE_ID = "c0e32108-6e3e-4ab8-96bd-cd61be6edb33";
export const STOP_ACTION_TEMPLATE_ID = "c0e32108-6e3e-4ab8-96bd-cd61be6edb34";
export const JOIN_TEMPLATE_ID = "fb6a9d14-dd15-420d-a2b2-fc637c0c37c6";
export const FOREACH_TEMPLATE_ID = "dbef0804-66a9-4f8f-872c-ece1b89b8fdb";

export const CALL_SUBPROCESS_TEMPLATE_ID =
  "c37e56fe-d924-4604-a86f-7c93f863fcdf";
export const TRIGGER_SUBPROCESS_TEMPLATE_ID =
  "615365f9-9ccb-dd46-85a6-af824b7be897";
export const GENERATE_DOCUMENT_TEMPLATE_ID =
  "cdae0149-ab39-4d07-a72f-549c64cd10fe";

export const DECISIONAL_TEMPLATE_ID = "f5dcbb04-253d-4061-99a1-9b2822c2e6d2";

export const NO_PROPERTIES_PANEL_ACTION_TEMPLATES_ID = [
  START_ACTION_TEMPLATE_ID,
  STOP_ACTION_TEMPLATE_ID,
  JOIN_TEMPLATE_ID,
  "",
];

export const NO_ERROR_PORT_ACTION_TEMPLATES_ID = [
  START_ACTION_TEMPLATE_ID,
  STOP_ACTION_TEMPLATE_ID,
  JOIN_TEMPLATE_ID,
];
