<template>
  <component
    v-if="setting.type !== 'ignore'"
    v-model="setting.value"
    class="pds-u-m--b--16"
    color="primary"
    :key="setting.id"
    :keyProp="setting.id"
    :is="getSettingComponent(setting)"
    :label="setting.label"
    :email="setting.value"
    :subject="setting.subject"
    :options="setting.options"
    :credentialType="setting.credentialsTemplateId"
    :isDisabled="setting.disabled || disabled"
    :disabled="setting.disabled || disabled"
    :settings="setting"
    :placeholder="setting.placeholder"
    :tooltip="setting.tooltip"
    :config="{
      columns: setting.columns,
      items: setting.value,
    }"
    :processVariables="processVariables"
    :parent="parent"
    :required="setting.isRequired"
    :id="setting.id"
    :isFullWidth="true"
    :tabs="setting.value"
    :type="isTextArea(setting.type)"
    :status="status"
    :parentFlow="flow"
    :storeDataAs="storeDataAs"
    :readonly="readonly"
    :ignoreLabelFormatting="ignoreLabelFormatting"
    :shouldPrevent="shouldPrevent"
    :draggable="draggable"
    :lang="setting.language"
    @click="$emit('ui', setting)"
    @update-input="$emit('update-input', $event)"
    @blur="$emit('blur', $event)"
    @click.native="clickHandler($event, setting)"
  >
    {{ setting.label }}
    <template
      v-for="slotName in ['prepend', 'singleLabel', 'placeholder']"
      v-slot:[slotName]="{ selectedOption, keyName, placeholder }"
    >
      <pds-icon
        v-if="getControlIcon(setting)"
        :key="slotName"
        :class="[
          'material-icons--grey',
          'pup-control-manager--direction-icon',
          getControlIconClass(setting),
        ]"
        :icon="getControlIcon(setting)"
        :isOutlined="false"
        size="tiny"
        v-tooltip="{
          content: getControlIconTooltip(setting),
          boundariesElement: 'window',
        }"
      />
      <template v-if="selectedOption"> {{ selectedOption[keyName] }}</template>
      <template v-if="placeholder"> {{ placeholder }}</template>
    </template>
  </component>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  IconComponent,
  InputComponent,
  SelectComponent,
  RadioboxComponent,
  ButtonComponent,
  CheckboxComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";

import {
  Direction,
  Node,
  Setting,
  SettingType,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { Flow } from "@/services/crud/Orchestration.service";

import UploadSettingComponent from "@/modules/ProcessDesigner/components/Controls/UploadSetting/UploadSetting.component.vue";

import Toast from "@/components/Toast/Toast.component.vue";
import TabsPayload from "@/modules/ProcessDesigner/components/Controls/TabsPayload/TabsPayload.component.vue";
import TabsPayloadOld from "@/modules/ProcessDesigner/components/Controls/TabsPayloadOld/TabsPayload.component.vue";
import ApiCall from "@/modules/ProcessDesigner/components/Controls/ApiCall/ApiCall.component.vue";
import VerbSelectComponent from "@/modules/ProcessDesigner/components/Controls/VerbSelect/VerbSelect.component.vue";
import TInput from "@/modules/ProcessDesigner/components/Controls/TableInputOld/TInput.component.vue";
import GenericText from "@/modules/ProcessDesigner/components/Controls/GenericText/GenericText.component.vue";
import Editor from "@/modules/ProcessDesigner/components/CodeEditors/Editor/Editor.component.vue";
import FormSelect from '@/modules/ProcessDesigner/components/Controls/FormSelect/FormSelect.component.vue';
import ScopedVariable from "@/modules/ProcessDesigner/components/Controls//ScopedVariable/ScopedVariable.component.vue";

import DecisionalManager from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/DecisionalManager.component.vue";
import DecisionalDefault from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/DecisionalDefault.component.vue";
import SubprocessBuilder from "@/modules/ProcessDesigner/components/Controls/SubprocessBuilder/SubprocessBuilder.component.vue";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import FlowSelectComponent from "../FlowSelect/FlowSelect.component.vue";
import ColumnDefinition from "@/modules/ProcessDesigner/components/Controls/ColumnDefinition/ColumnDefinition.component.vue";
import DelayTypeRadio from "@/modules/ProcessDesigner/components/Controls/Delay/TypeRadio/DelayTypeRadio.component.vue";
import DelayDefinition from "@/modules/ProcessDesigner/components/Controls/Delay/Definition/DelayDefinition.component.vue";
import MapProcessData from "@/modules/ProcessDesigner/components/Controls/MapProcessData/MapProcessData.component.vue";
import DateTimeComponent from "@/modules/ProcessDesigner/components/Controls/DateTime/DateTime.component.vue";
import DocumentSelectComponent from "@/modules/ProcessDesigner/components/Controls/DocumentMapper/DocumentSelect.component.vue";
import DocumentMapperBuilderComponent from "@/modules/ProcessDesigner/components/Controls/DocumentMapper/DocumentMapperBuilder.component.vue";
import { Controls } from "../../PropertiesPanel/Utils/Controls";
import { Operator } from "@/modules/CredentialManager/components/Form/Controls.model";
import { EventBus } from "@/utils/eventBus";

@Component({
  components: {
    "pds-icon": IconComponent,
    "pds-input": InputComponent,
    "pds-checkbox": CheckboxComponent,
    "pds-select": SelectComponent,
    "pds-radio": RadioboxComponent,
    "pds-button": ButtonComponent,
    "upload-setting": UploadSettingComponent,
    "pup-credential-select": () =>
      import(
        "@/modules/ProcessDesigner/components/Controls/CredentialSelect/CredentialSelect.component.vue"
      ),
    "pup-toast": Toast,
    "pds-apicall": ApiCall,
    "pds-verbselect": VerbSelectComponent,
    "pup-table": TInput,
    "pup-tabs-payload-old": TabsPayloadOld,
    "pup-tabs-payload": TabsPayload,
    "pds-sidepanel": () =>
      import(
        "@/modules/ProcessDesigner/components/Controls/SidePanel/SidePanel.control.vue"
      ),
    "pup-generic-text": GenericText,
    "pup-editor": Editor,
    "pds-variable": ScopedVariable,
    "decisional-manager": DecisionalManager,
    "decisional-default": DecisionalDefault,
    "subprocess-builder": SubprocessBuilder,
    "flow-select": FlowSelectComponent,
    "column-definition": ColumnDefinition,
    "delay-type-radio": DelayTypeRadio,
    "delay-definition": DelayDefinition,
    "map-process-data": MapProcessData,
    "pup-date-time": DateTimeComponent,
    "pup-document-select": DocumentSelectComponent,
    "pup-document-mapper-builder": DocumentMapperBuilderComponent,
    "pup-form-select": FormSelect,
  },
  name: "pup-control-global",
})
export default class ControlsManager extends mixins(Controls) {
  @Prop({ required: true }) setting!: Setting;

  @Prop() parent!: Node;

  @Prop() status!: PdsTypes.InputStatus;

  @Prop() storeDataAs!: Setting;

  @Prop() processVariables!: ProcessVariable[];

  @Prop() flow!: Flow | null;

  @Prop({ default: false, type: Boolean }) readonly!: boolean;

  @Prop({ default: false, type: Boolean }) disabled!: boolean;

  @Prop({ default: false, type: Boolean }) shouldPrevent!: boolean;

  @Prop({ default: false, type: Boolean }) draggable!: boolean;

  @Prop({ default: true, type: Boolean }) ignoreLabelFormatting!: boolean;

  getSettingComponent(setting: Setting) {
    if (setting.condition) {
      const { dependencyId, operator, value } = setting.condition;

      let dependencyControl: Setting | null = null;

      if (this.parent && this.parent.configuration) {
        this.traverseControls(this.parent.configuration, (control) => {
          if (control.id === dependencyId) {
            dependencyControl = control;
          }
        });

        if (dependencyControl) {
          if (operator === Operator.EQUALS) {
            if ((dependencyControl as Setting).value !== value) {
              return false;
            }
          }
        }
      }
    }

    switch (setting.type) {
      case SettingType.TEXT:
      case SettingType.TEXT_AREA:
        return "pup-generic-text";

      case SettingType.SELECT:
        return "pds-select";

      case SettingType.RADIO:
        return "pds-radio";

      case SettingType.CHECKBOX:
        return "pds-checkbox";

      case SettingType.BUTTON:
      case SettingType.FILTER_DATA:
      case SettingType.EMAIL_MODAL:
      case SettingType.MODAL:
        return "pds-button";

      case SettingType.UPLOAD_MODAL:
        // return "upload-setting";
        return "pds-button";

      case SettingType.SIDEPANEL:
        return "pds-sidepanel";

      case SettingType.CREDENTIAL:
        return "pup-credential-select";

      case SettingType.TOAST:
        return "pup-toast";

      case SettingType.TABS_PAYLOAD_OLD:
        return "pup-tabs-payload-old";

      case SettingType.TABS_PAYLOAD:
        return "pup-tabs-payload";

      case SettingType.API_CAPP:
        return "pds-apicall";

      case SettingType.VERB_SELECT:
        return "pds-verbselect";

      case SettingType.TEXT_BASIC:
      case SettingType.TEXT_AREA_BASIC:
        return "pds-input";

      case SettingType.TABLE:
        return "pup-table";

      case SettingType.CODE_EDITOR:
        return "pup-editor";

      case SettingType.SCOPED_VARIABLE:
        if (!setting.isList) {
          return "pds-variable";
        }
        return "pup-generic-text";

      case SettingType.DECISIONAL_DEFAULT:
        return "decisional-default";

      case SettingType.DECISIONAL_CASES:
        return "decisional-manager";

      case SettingType.PROCESS_INPUT:
      case SettingType.PROCESS_OUTPUT:
        return "subprocess-builder";

      case SettingType.FLOW_LIST:
        return "flow-select";

      case SettingType.COLUMN_DEFINITION:
        return "column-definition";

      case SettingType.DELAY_TYPE_RADIO_SELECT:
        return "delay-type-radio";

      case SettingType.DELAY_DEFINITION:
        return "delay-definition";

      case SettingType.MAP_PROCESS_DATA:
        return "map-process-data";

      case SettingType.DATE_TIME:
        return "pup-date-time";

      case SettingType.DOCUMENT_SELECT:
        return "pup-document-select";

      case SettingType.DOCUMENT_MAPPER_BUILDER:
        return "pup-document-mapper-builder";

      case SettingType.FORM_TEMPLATE:
        return "pup-form-select";

      default:
        return "pup-generic-text";
    }
  }

  isTextArea(type: SettingType) {
    if ([SettingType.TEXT_AREA, SettingType.TEXT_AREA_BASIC].includes(type)) {
      return "textarea";
    }
    return "ghost";
  }

  clickHandler(event: MouseEvent, setting: Setting) {
    if (this.shouldPrevent) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    EventBus.$emit("control-clicked", setting);
  }

  getControlIcon(setting: Setting) {
    switch (setting.direction) {
      case Direction.Input:
        return "icon-input";
      case Direction.InputOutput:
        return "icon-input-output";
      case Direction.Output:
        return "icon-output";
      default:
        return null;
    }
  }

  getControlIconTooltip(setting: Setting) {
    switch (setting.direction) {
      case Direction.Input:
        return "Input";
      case Direction.InputOutput:
        return "Input/Output";
      case Direction.Output:
        return "Output";
      default:
        return null;
    }
  }

  getControlIconClass(setting: Setting) {
    switch (this.getSettingComponent(setting)) {
      case "pup-generic-text":
        return "pds-u-m--l--8";
      default:
        return "pds-u-m--r--8";
    }
  }
}
</script>

<style lang="scss">
@import "./ControlsManager.component.scss";
</style>
